import React, {useState, useEffect } from "react"
import SEO from "../components/seo"
import DatalayerEvents from "../../services/datalayer"
import "../css/explorestudi.css"

export default function Explorestudi() {
  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init()
  }, [])
  useEffect(() => {
    document.getElementById("exploreModal").style.display = "block"
    document.getElementById("exploreModal").classList.add("show")
  }, [])

  return (
    <div>
      <SEO
        title="A tour of study material from Class 1-8 CBSE | Tata Studi"
        description="Let's explore excellent Study Material that is easy to understand and makes learning engaging. Take a guided tour with Tata Studi and explore the study material for Students from Class 1-8 CBSE on India's favourite learning platform Studi by Tata ClassEdge"
        lang="en"
      />
      <h1 class="displayNone">Take a Tour with Tata Studi</h1>
      <div style={{ height: "100vh", backgroundColor: "white" }}></div>
      <div
        className="modal fade show"
        id="exploreModal"
        tabIndex="-1"
        aria-labelledby="exploreModalLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-explore">
          <div className="modal-content">
            <div className="modal-body explore-modal-body">
              <div className="embed-responsive embed-responsive-16by9 modal-iframe">
                <iframe
                  src="https://d3lqgv46fe81ya.cloudfront.net/index.html"
                  height="100%"
                  width="100%"
                  className="embed-responsive-item"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
